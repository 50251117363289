import React, { memo } from "react"
import { Stack, styled } from "@mui/material"

import { useLocalizationContext } from "@context"
import { Cover } from "../cover"
import { Dashboard } from "../dashboard"
import { MapFilters } from "./fragments"
import { useMainMap } from "./_useMainMap"

const DashboardMemo = memo(Dashboard)

export const MainMap: React.FC = () => {
  const {
    l: {
      map: { title },
    },
  } = useLocalizationContext()

  const { setFilters } = useMainMap()

  return (
    <Cover title={title}>
      <SContainer>
        <MapFilters setFilters={setFilters} />

        <SMapContainer>
          <MapRoot id={"map"} />
          <DashboardMemo />
        </SMapContainer>
      </SContainer>
    </Cover>
  )
}

const SContainer = styled(Stack)(({ theme }) => ({
  gap: "20px",
  height: "100%",
  width: "100%",
  padding: "10px 20px 20px",

  [theme.breakpoints.down("lg")]: {
    gap: "10px",
  },
}))

const SMapContainer = styled(Stack)({
  flexDirection: "row",
  height: "100%",
  width: "100%",
  gap: "20px",
})

const MapRoot = styled("div")({
  width: "100%",
  height: "100%",
})
