import { EAll, ELayers, EMagValues, ILocale } from "@types"

const getLayerSelectOptions = (l: ILocale): Record<EAll | ELayers, string> => {
  const { all, stations, quakes, predArea, buildingDens, populationDens, tailingsDams } = l.mapFilters

  return {
    [EAll.ALL]: all,
    [ELayers.STATIONS]: stations,
    [ELayers.QUAKES]: quakes,
    [ELayers.PRED_AREA]: predArea,
    [ELayers.BUILDINGS]: buildingDens,
    [ELayers.POPULATION]: populationDens,
    [ELayers.TAILINGS]: tailingsDams,
  }
}

const getMagFilterOptions = (l: ILocale): Record<EAll | EMagValues, string> => {
  const { all } = l.mapFilters

  return {
    [EAll.ALL]: all,
    [EMagValues.TWO]: "<3",
    [EMagValues.THREE]: "3",
    [EMagValues.FOUR]: "4",
    [EMagValues.FIVE]: "5",
    [EMagValues.SIX]: "6+",
  }
}

export { getLayerSelectOptions, getMagFilterOptions }
