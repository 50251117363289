import { theme } from "@constants"
import { EMagValues } from "@types"

const magColors = {
  [EMagValues.TWO]: theme.palette.magnitude.A100!,
  [EMagValues.THREE]: theme.palette.magnitude.A200!,
  [EMagValues.FOUR]: theme.palette.magnitude.A300!,
  [EMagValues.FIVE]: theme.palette.magnitude.A400!,
  [EMagValues.SIX]: theme.palette.magnitude.A500!,
}

function getMagnitudeEValue(magnitude: number): EMagValues {
  if (magnitude < 3) return EMagValues.TWO
  if (magnitude >= 3 && magnitude < 4) return EMagValues.THREE
  if (magnitude >= 4 && magnitude < 5) return EMagValues.FOUR
  if (magnitude >= 5 && magnitude < 6) return EMagValues.FIVE
  return EMagValues.SIX
}

function setStyle(element: HTMLElement, style: Partial<CSSStyleDeclaration>): void {
  for (const key in style) {
    element.style[key] = style[key]!
  }
}

function createCustomElement(tagName: string, style: Partial<CSSStyleDeclaration>): HTMLElement {
  const element = document.createElement(tagName)

  setStyle(element, style)

  return element
}

export { magColors, getMagnitudeEValue, setStyle, createCustomElement }
