import React, { useCallback, useMemo, useState } from "react"
import { ToggleButtonGroup, ToggleButton, styled, Box, Stack, Typography } from "@mui/material"

import { EAll, EMagValues, TSetMapFilters } from "@types"
import { magColors } from "../../../_utils"
import { useLatest } from "@utils"
import { getMagFilterOptions } from "./_constants"
import { useLocalizationContext } from "@context"

type TMagValues = EMagValues | EAll

interface IMagFilter {
  setFilters: TSetMapFilters
}

export const MagFilter: React.FC<IMagFilter> = ({ setFilters }) => {
  const { l } = useLocalizationContext()
  const magFilterOptions = useMemo(() => getMagFilterOptions(l), [l])

  const [selectedMag, setSelectedMag] = useState<TMagValues[]>([EAll.ALL])

  const selectedMagRef = useLatest(selectedMag)

  const handleChange = useCallback((_: React.MouseEvent<HTMLElement, MouseEvent>, newValues: TMagValues[]) => {
    const wasAll = selectedMagRef.current.includes(EAll.ALL)
    const nowAll = newValues.includes(EAll.ALL)

    const adjustedValues = ((): TMagValues[] => {
      if (wasAll && newValues.some((val) => val !== EAll.ALL)) return newValues.filter((val) => val !== EAll.ALL)
      if (!wasAll && nowAll) return [EAll.ALL]
      if (Object.keys(EMagValues).every((key) => newValues.includes(key as EMagValues))) return [EAll.ALL]

      return newValues
    })()

    setSelectedMag(adjustedValues)
    setFilters({ mag: adjustedValues })
  }, [])

  const buttons = useMemo(
    () =>
      Object.keys(magFilterOptions).map((key, index) => {
        return (
          <SToggleButton
            key={key}
            value={key}
            sx={{
              width: key === EAll.ALL ? "45px" : "32px",
            }}
          >
            {key !== EAll.ALL && (
              <SColorMarker
                sx={{
                  borderRadius: index === Object.keys(magFilterOptions).length - 1 ? "0 0 2px 0" : 0,
                  background: magColors[key as EMagValues],
                }}
              />
            )}
            {magFilterOptions[key as EMagValues]}
          </SToggleButton>
        )
      }),
    []
  )

  return (
    <Stack direction="row" alignItems="center" gap="8px">
      <Typography variant="body3">{l.mapFilters.magnitude}</Typography>

      <ToggleButtonGroup value={selectedMag} onChange={handleChange} sx={{ width: "200px" }}>
        {buttons}
      </ToggleButtonGroup>
    </Stack>
  )
}

const SToggleButton = styled(ToggleButton)({
  height: "32px",
  position: "relative",
})

const SColorMarker = styled(Box)({
  position: "absolute",
  width: "31px",
  height: "4px",
  bottom: 0,
  left: 0,
})
