import React, { useCallback, useMemo, useState } from "react"
import { Select, styled, MenuItem, Checkbox, Typography, SelectChangeEvent, Stack } from "@mui/material"

import { EAll, ELayers, TSetMapFilters } from "@types"
import { useLatest } from "@utils"
import { getLayerSelectOptions } from "./_constants"
import { useLocalizationContext } from "@context"

const allValues = Object.keys(ELayers) as ELayers[]

type TLayers = ELayers | EAll

interface ILayerSelect {
  setFilters: TSetMapFilters
}

export const LayerSelect: React.FC<ILayerSelect> = ({ setFilters }) => {
  const { l } = useLocalizationContext()
  const layerSelectOptions = useMemo(() => getLayerSelectOptions(l), [l])

  const [selectedLayers, setSelectedLayers] = useState<TLayers[]>([ELayers.QUAKES, ELayers.STATIONS])

  const selectedLayersRef = useLatest(selectedLayers)

  const handleLayerChange = useCallback((e: SelectChangeEvent<unknown>) => {
    const oldValues = selectedLayersRef.current
    const newValues = e.target.value as TLayers[]

    const wasAll = oldValues.includes(EAll.ALL)
    const nowAll = newValues.includes(EAll.ALL)

    const adjustedValues = ((): TLayers[] => {
      if (wasAll) return nowAll ? newValues.filter((val) => val !== EAll.ALL) : []
      if (nowAll || allValues.every((key) => newValues.includes(key))) return [EAll.ALL, ...allValues]

      return newValues
    })()

    setSelectedLayers(adjustedValues)
    setFilters({ layers: adjustedValues })
  }, [])

  const renderValue = useCallback(
    (ids: TLayers[]): React.ReactNode => {
      if (!ids.length) return "Не выбрано"
      if (ids.includes(EAll.ALL)) return l.mapFilters.all

      const names = ids.map((id: TLayers) => layerSelectOptions[id])

      return names.join(", ")
    },
    [l]
  )

  const layerMenuItems = useMemo(
    () =>
      Object.keys(layerSelectOptions).map((key): React.ReactNode => {
        return (
          <SMenuItem key={key} value={key}>
            <Checkbox checked={selectedLayers.includes(key as TLayers)} />
            <Typography>{layerSelectOptions[key as TLayers]}</Typography>
          </SMenuItem>
        )
      }),
    [selectedLayers]
  )

  return (
    <Stack direction="row" alignItems="center" gap="8px">
      <Typography variant="body3">{l.mapFilters.layers}</Typography>
      <SSelect
        multiple
        value={selectedLayers}
        onChange={handleLayerChange}
        renderValue={renderValue as (value: unknown) => React.ReactNode}
        children={layerMenuItems}
        sx={{ display: "flex", flexDirection: "row" }}
      />
    </Stack>
  )
}

const SSelect = styled(Select)(({ theme }) => ({
  height: "32px",
  width: "200px",

  [theme.breakpoints.down("lg")]: {
    width: "128px",
  },
}))

const SMenuItem = styled(MenuItem)({
  height: "32px",
})
