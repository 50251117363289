import React, { useCallback, useMemo, useState } from "react"
import { Select, styled, MenuItem, Checkbox, Typography, SelectChangeEvent, Stack } from "@mui/material"

import { EAll, ETimeOptions, TSetMapFilters, TTimeOptions } from "@types"
import { useLocalizationContext } from "@context"
import { getTimeSelectOptions } from "@utils"

interface ITimeSelect {
  setFilters: TSetMapFilters
}

export const TimeSelect: React.FC<ITimeSelect> = ({ setFilters }) => {
  const { l } = useLocalizationContext()
  const timeSelectOptions = useMemo(() => getTimeSelectOptions(l), [l])

  const [selectedTime, setSelectedTime] = useState<TTimeOptions>(EAll.ALL)

  const handleTimeChange = useCallback((e: SelectChangeEvent<unknown>) => {
    const newValue = e.target.value as TTimeOptions

    const adjustedValues = ((): TTimeOptions[] => {
      if (newValue === EAll.ALL) return [EAll.ALL]

      const keys = Object.keys(ETimeOptions) as ETimeOptions[]
      const targetIndex = keys.indexOf(newValue)

      return keys.slice(0, targetIndex + 1) as TTimeOptions[]
    })()

    setSelectedTime(newValue)
    setFilters({ time: adjustedValues })
  }, [])

  const renderValue = useCallback((val: TTimeOptions): React.ReactNode => timeSelectOptions[val], [])

  const layerMenuItems = useMemo(
    () =>
      Object.keys(timeSelectOptions).map((key): React.ReactNode => {
        return (
          <SMenuItem key={key} value={key}>
            <Checkbox checked={selectedTime.includes(key as TTimeOptions)} />
            <Typography>{timeSelectOptions[key as TTimeOptions]}</Typography>
          </SMenuItem>
        )
      }),
    [selectedTime]
  )

  return (
    <Stack direction="row" alignItems="center" gap="8px">
      <Typography variant="body3">{l.mapFilters.time}</Typography>

      <SSelect
        value={selectedTime}
        onChange={handleTimeChange}
        renderValue={renderValue as (value: unknown) => React.ReactNode}
        children={layerMenuItems}
        sx={{ display: "flex", flexDirection: "row" }}
      />
    </Stack>
  )
}

const SSelect = styled(Select)(({ theme }) => ({
  height: "32px",
  width: "200px",

  [theme.breakpoints.down("lg")]: {
    width: "130px",
  },
}))

const SMenuItem = styled(MenuItem)({
  height: "32px",
})
