import { EAll, ELayers, EMagValues, ETimeOptions, IMapFilterValues, TSetMapFilters } from "@types"

const initValues = { layers: [ELayers.QUAKES, ELayers.STATIONS], mag: [EAll.ALL], time: [EAll.ALL] }

export class FilterController {
  private _filters: IMapFilterValues

  constructor(initialValues?: IMapFilterValues) {
    this._filters = initialValues ?? initValues
  }

  get filters(): IMapFilterValues {
    return this._filters
  }

  setFilters: TSetMapFilters = (newFilters: Partial<IMapFilterValues>) => {
    if (JSON.stringify(this._filters) === JSON.stringify(newFilters)) return

    this._filters = { ...this._filters, ...newFilters }
  }

  hasPassedFilters = <T extends EMagValues | ELayers | ETimeOptions | EAll>(value: T): boolean => {
    const { layers, mag, time } = this._filters

    if (isELayers(value)) return layers.includes(EAll.ALL) || layers.includes(value)
    if (isEMagValues(value)) return mag.includes(EAll.ALL) || mag.includes(value)
    if (isETimeOptions(value) || isEAll(value)) return time.includes(EAll.ALL) || time.includes(value)

    return false
  }
}

function isEAll(value: string): value is EAll {
  return Object.values<string>(EAll).includes(value)
}

function isEMagValues(value: string): value is EMagValues {
  return Object.values<string>(EMagValues).includes(value)
}

function isELayers(value: string): value is ELayers {
  return Object.values<string>(ELayers).includes(value)
}

function isETimeOptions(value: string): value is ETimeOptions {
  return Object.values<string>(ETimeOptions).includes(value)
}
