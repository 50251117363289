/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useMemo, useRef } from "react"

import { useDemoScriptContext, useGoogleMapsContext, useLocalizationContext } from "@context"
import { mapOptions, styles } from "./_map_config"
import { getAreasOverlay, getQuakesOverlay, getStationsOverlay, getLayersOverlay } from "./map_overlays"
import { EMessageType, IDashboardParams, IObserver, IQuake, IStation, TSetMapFilters } from "@types"
import { FilterController } from "./fragments"
import { useLatest } from "@utils"

import { tailings, buildings, population } from "@constants"
import { TAreasOL, TLayersOL, TQuakesOL, TStationsOL } from "./_types"
interface IUseMainMapReturn {
  setFilters: TSetMapFilters
}

export const useMainMap = (): IUseMainMapReturn => {
  const { l } = useLocalizationContext()
  const { googleMaps } = useGoogleMapsContext()
  const { demoScript, stations, quakes, AIData } = useDemoScriptContext()

  const mapRef = useRef<google.maps.Map | null>(null)

  const stationsRef = useRef<TStationsOL | null>(null)
  const quakesRef = useRef<TQuakesOL | null>(null)
  const areasRef = useRef<TAreasOL | null>(null)
  const layersRef = useRef<TLayersOL | null>(null)

  const filterController = useMemo(() => new FilterController(), [])

  const setFilters = useCallback<TSetMapFilters>((newFilters) => {
    filterController.setFilters(newFilters)

    stationsRef.current?.filter()
    quakesRef.current?.filter()
    areasRef.current?.filter()
    layersRef.current?.filter()
  }, [])

  const aiRef = useLatest(AIData)

  const observers = useMemo(
    () => [
      {
        cb: (data: Partial<IQuake>): void => {
          quakesRef.current?.onMessage(data)
        },
        type: EMessageType.QUAKE,
      },

      {
        cb: (data: Partial<IStation>): void => {
          data.isActive !== undefined && stationsRef.current?.setActive(data.id!, data.isActive)
        },
        type: EMessageType.STATION,
      },

      {
        cb: (data: IDashboardParams): void => {
          areasRef.current?.setProbability(data.probability)
        },
        type: EMessageType.AI,
      },

      {
        cb: (): void => {
          stationsRef.current?.reset(structuredClone(stations))
          quakesRef.current?.reset(structuredClone(quakes))
          areasRef.current?.setProbability(aiRef.current.probability)
        },
        type: EMessageType.STOP,
      },
    ],
    []
  )

  useEffect(() => {
    if (!googleMaps) return

    mapRef.current = new googleMaps.Map(document.getElementById("map")!, mapOptions)
    mapRef.current.setOptions({ styles })

    const StationsOverlay = getStationsOverlay()
    stationsRef.current = new StationsOverlay(structuredClone(stations), filterController)
    stationsRef.current.setMap(mapRef.current!)

    const QuakesOverlay = getQuakesOverlay()
    quakesRef.current = new QuakesOverlay(structuredClone(quakes), filterController, l)
    quakesRef.current.setMap(mapRef.current!)
    mapRef.current.addListener("click", <T extends { latLng: google.maps.LatLng }>({ latLng: { lat, lng } }: T) => {
      //   console.log(lat(), lng())

      quakesRef.current?.closeTooltip()
    })

    const AreasOverlay = getAreasOverlay()
    areasRef.current = new AreasOverlay(mapRef.current!, AIData.probability, AIData.bounds, filterController)

    const LayersOverlay = getLayersOverlay()
    layersRef.current = new LayersOverlay({ tailings, buildings, population }, filterController)
    layersRef.current.setMap(mapRef.current!)

    demoScript.on(observers as IObserver<EMessageType>[])

    return () => demoScript.off(observers as IObserver<EMessageType>[])
  }, [googleMaps])

  useEffect(() => {
    areasRef.current?.setBounds(AIData.bounds)
  }, [AIData])

  return { setFilters }
}
