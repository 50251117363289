import React from "react"
import { Stack, styled } from "@mui/material"
import { MagFilter, LayerSelect, TimeSelect } from "./fragments"
import { TSetMapFilters } from "@types"

interface IMapFilters {
  setFilters: TSetMapFilters
}

export const MapFilters: React.FC<IMapFilters> = ({ setFilters }) => {
  return (
    <SFilterContainer>
      <TimeSelect setFilters={setFilters} />
      <MagFilter setFilters={setFilters} />
      <LayerSelect setFilters={setFilters} />
    </SFilterContainer>
  )
}

const SFilterContainer = styled(Stack)({
  flexDirection: "row",
  gap: "20px",
})
